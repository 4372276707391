export const descriptions = [
    "Snag this one quick! It's available.",
    "Grab this sweet domain before someone else does.",
    "Start the next big thing with this one.",
    "You know you want it. Don't wait to get the domain you really want, get it now!",
    "Don't think back to what could've been. Get the domain and get going.",
    "Look no further, the domain you want is here AND available!",
    "Make an offer, the answer might just surprise you! Or not, lowballer...",
    "A couple clicks and an email or two and this thing is all yours.",
    "We want to sell this thing. The question is, do you want to buy it?!"
]
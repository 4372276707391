import React, {Component, Fragment} from "react";
import Header from "../components/Header";

const AppLayout = ({children}) => {
    return (
        <Fragment>
            <Header/>
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                {children}
            </div>
        </Fragment>
    )
}

export default AppLayout;

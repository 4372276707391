import React, {useState} from 'react';
import {SpeakerphoneOutline, XOutline} from "heroicons-react";

const Banner = ({ text, mobileText, buttonText }: { text: string, mobileText?: string, buttonText?: string}) => {
    const [display, setDisplay] = useState(true)
    return display ? (
        <div className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5">
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                <div className="p-2 rounded-lg bg-pink shadow-lg sm:p-3">
                    <div className="flex items-center justify-between flex-wrap">
                        <div className="w-0 flex-1 flex items-center">
                             <span className="flex p-2 rounded-lg bg-pink-light">
                                  <SpeakerphoneOutline className={'h-6 w-6 text-white'}/>
                              </span>
                                                <p className="ml-3 font-medium text-white truncate">
                                <span className="md:hidden">
                                  { mobileText }
                                </span>
                                                    <span className="hidden md:inline">
                                  { text }
                                </span>
                            </p>
                        </div>
                        { buttonText ? (<div className="order-3 mt-2 flex-shrink-0 w-full sm:order-2 sm:mt-0 sm:w-auto">
                            <a href="#"
                               className="flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-indigo-600 bg-white hover:bg-indigo-50">
                                { buttonText }
                            </a>
                        </div>) : null}
                        <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
                            <button onClick={() => setDisplay(!display)} type="button"
                                    className="-mr-1 flex p-2 rounded-md hover:bg-pink-light focus:outline-none focus:ring-2 focus:ring-white">
                                <span className="sr-only">Dismiss</span>
                                <XOutline className={'h-6 w-6 text-white'}/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ): null;
};

export default Banner;

import React from 'react';
import {Check, LockClosed} from "heroicons-react";
import {Domain, domains, descriptions, buttons} from "../constants";

const Card = ({name, description, price, callback}: Domain) => {
    const getRandomItem = (arr: string | any[]) => {
        return arr[Math.floor(Math.random() * arr.length)]
    }

    return (
        <div
            className="h-full shadow-md hover:shadow-sm transition:shadow duration-500 ease-in-out border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200">
            <div className="p-6 max-h-full h-60 flex flex-col">
                <code style={{textShadow: "0 1px 1px rgb(0 0 0 / 33%)"}}
                      className="text-md leading-6 font-semibold text-gray-700">{name}</code>
                <p className="flex-grow mt-4 text-sm text-gray-500">{description ? description : getRandomItem(descriptions)}</p>
                { price ? (<button onClick={ callback? () => callback(name) : () => {}}
                        className="self-end domain-button bottom-0 mt-8 block w-full border-2 border-pink rounded-md py-2 ease-in-out duration-500 hover:ease-in-out text-sm font-semibold hover:text-pink text-white text-center bg-pink hover:bg-transparent shadow-pink hover:shadow-none focus:outline-none focus:ring focus:ring-pink">{price ? `Buy For $${price}` : getRandomItem(buttons)}</button>)
                    : <button onClick={ callback? () => callback(name) : () => {}}
                        className="self-end domain-button bottom-0 mt-8 block w-full bg-transparent border-2 border-pink rounded-md py-2 ease-in-out duration-500 hover:ease-in-out text-sm font-semibold text-pink hover:text-white text-center hover:bg-pink hover:shadow-pink focus:outline-none focus:ring focus:ring-pink">{price ? `Buy For $${price}` : getRandomItem(buttons)}</button> }
            </div>
            <div className="py-4 px-6 w-full">
                <a href={`https://www.whatsmydns.net/#TXT/${name}`} target={'_blank'}>
                    <button type="button" className="w-full inline-flex items-center px-2.5 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink">
                        <LockClosed className={'h-3 w-3'}/>
                        <p className={'m-auto'}>Verify Domain Ownership</p>
                    </button>
                </a>
            </div>
        </div>
    );
};

export default Card;

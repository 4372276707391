import React, {Component} from "react";
import Logo from "../assets/img/sweetdomains-script.svg";
import {Link} from "react-router-dom";
import {BellOutline, Menu} from "heroicons-react";

class Header extends Component {

    state = {
        showNavBarBoxShadow: false
    };

    listenScrollEvent = (e: any) => {
        if (window.scrollY > 350) {
            this.setState({showNavBarBoxShadow: true})
        } else {
            this.setState({showNavBarBoxShadow: false})
        }
    };

    componentDidMount() {
        window.addEventListener('scroll', this.listenScrollEvent)
    }

    shouldComponentUpdate(nextProps: any, nextState: any, nextContext: any): boolean {
        return this.state.showNavBarBoxShadow !== nextState.showNavBarBoxShadow;
    }


    render() {
        return (
            <nav className="bg-white">
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex justify-between h-16">
                        <div className="flex">
                            <div className="flex-shrink-0 flex items-center">
                                <Link to={'/'}>
                                    <img className="block h-16 w-auto"
                                         src={Logo}
                                         alt="SweetDomains.io"/>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Header;

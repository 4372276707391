import React from 'react';
import SweetDomainsLogo from '../assets/img/sweetdomains-logo.svg';

const Modal = ({children, domain, callback}: { children: any, domain: string, callback: Function }) => {
    return (
        <div className="fixed z-50 inset-0" aria-labelledby="dialog-1-title" role="dialog" aria-modal="true">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">

                  {/*Background overlay, show/hide based on modal state.*/}

                  {/*Entering: "ease-out duration-300"*/}
                  {/*  From: "opacity-0"*/}
                  {/*  To: "opacity-100"*/}
                  {/*Leaving: "ease-in duration-200"*/}
                  {/*  From: "opacity-100"*/}
                  {/*  To: "opacity-0"*/}

                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"/>

                 {/*This element is to trick the browser into centering the modal contents. */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>


                  {/*Modal panel, show/hide based on modal state.*/}

                  {/*Entering: "ease-out duration-300"*/}
                  {/*  From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"*/}
                  {/*  To: "opacity-100 translate-y-0 sm:scale-100"*/}
                  {/*Leaving: "ease-in duration-200"*/}
                  {/*  From: "opacity-100 translate-y-0 sm:scale-100"*/}
                  {/*  To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"*/}

                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-2 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-16 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                    <div>
                        <img src={SweetDomainsLogo} alt={'SweetDomains.io'}/>
                        <div>
                            <p className="text-sm text-center py-4">We typically respond in 24 hours or less.</p>
                        </div>
                        {children}
                    </div>
                    <div className="w-full">
                        <button onClick={() => callback()} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-pink sm:mt-0 sm:col-start-1 sm:text-sm">
                            Nah, forget it.
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Modal;

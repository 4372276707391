export const buttons = [
    "Reach Out, Yo.",
    "Get In Touch.",
    "Make Some Moves.",
    "Secure The Domain.",
    "Get The Ball Rolling.",
    "Take The First Step.",
    "Hit Us Up.",
    "Make It Happen.",
    "Do It Up.",
    "Get It Done.",
    "Start Building It.",
    "Just Do It Already.",
    "Why Wait?"
]
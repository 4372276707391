import React from 'react';

interface IColumnComponent {
    children?: any,
    xs?: string | number,
    sm?: string | number,
    md?: string | number,
    lg?: string | number,
    xl?: string | number,
    height?: string | number,
    className?: string
}

const Column = (props: IColumnComponent) => {
    return (
        <div className={props.className}>{props.children}</div>
    );
};

export default Column;
export interface Domain {
    name: string,
    description?: string,
    price?: number,
    callback?: Function
}
export const domains: Array<Domain> = [
    { name: "product.vc", description: "Premium domain for a wide array of Venture Capital firms!" },
    { name: "engineer.so"},
    { name: "algorithm.so"},
    { name: "memes.so"},
    { name: "entry.so"},
    { name: "form.so"},
    { name: "softwareremote.com"},
    { name: "formreach.com"},
    { name: "conditional.ai", description: "One word, programming relevant .AI domain name to start the company off the future." },
    { name: "generalcloud.io", description: "Cloud computing is where it's at! Get this brandable domain for cheap." },
    { name: "rallyshift.com", description: "Great domain for an automotive parts store or racing blog.", price: 699 },
    { name: "goresu.me", description: "The ultimate Resume software domain name that's catchy, unique, and brandable.", price: 899 },
    { name: "strtup.io", description: "Create the hub for startup resources with the catchy domain name.", price: 795 },
    { name: "opensrc.io", description: "Ideal for open source software projects!", price: 645 },
    { name: "levrij.com", description: "Use your 'leverage' to buy this sweet, catchy, kinda short domain." },
    { name: "codelive.io", description: "Premium domain focused around software, coding, and programmers!" },
    { name: "hackchain.io", description: "Hackathons! Blockchain! Get two of the hottest tech buzzwords smushed together.", price: 899 },
    { name: "codesnippet.io", description: "Code snippets make the web go round. Don't let this one slip!" },
    { name: "textmktg.com", description: "Text Message / SMS marketing is a booming industry. Land yourself this catchy, relevant domain." },
    { name: "hugeforms.com", description: "No one likes small forms, get yourself HUGE forms.", price: 299 },
    { name: "sendpint.com", description: "Go ahead and send that internet buddy of yours a pint. Cheers mate.", price: 499 },
    { name: "loanuse.com", description: "Loans for trains, planes, and automobiles. Whatever your use - Loan Use is a sweet domain." },
    { name: "softwaretools.io" },
    { name: "scooped.io", price: 499 },
    { name: "contrac.io" },
    { name: "launchpack.io" },
    { name: "niftygeneral.com"},
    { name: "niftydownload.com"},
    { name: "standardnft.com"},
    { name: "generalnft.com"},
    { name: "niftypremium.com"},
    { name: "niftymake.com"},
    { name: "niftiesss.com"},
    { name: "niftysnips.com"},
    { name: "niftybeginner.com"},
    { name: "niftyventure.com"},
    { name: "niftycourses.com"},
    { name: "niftysupreme.com"},
    { name: "niftyyy.com"},
    { name: "niftyblack.com"},
    { name: "niftysimple.com"},
    { name: "domainpage.io"},
    { name: "nifty.boston"},
    { name: "paircoding.io" },
    { name: "pairprogramming.io" },
    { name: "pairprogramming.club"},
    { name: "utilized.io" },
    { name: "regularly.io" },
    { name: "sampled.io" },
    { name: "searchtrends.io" },
    { name: "policybasic.com" },
    { name: "policyfresh.com" },
    { name: "contentwriters.io" },
    { name: "speller.io" },
    { name: "puffed.io" },
    { name: "stackdirectory.com" },
    { name: "surveykeep.com" },
    { name: "transcribed.io" },
    { name: "trendcomparison.com" },
    { name: "unscheduled.io" },
    { name: "vergeclick.com" },
    { name: "alertjam.com" },
    { name: "assetmake.com" },
    { name: "bidenmemes.co" },
    { name: "kamalamemes.co" },
    { name: "cocktailsoftware.com" },
    { name: "comparehelper.com" },
    { name: "convertview.com" },
    { name: "hoverleads.com" },
    { name: "maintenancepage.io" },
    { name: "mixtrace.com" },
    { name: "overflowed.io" },
    { name: "api.boston" },
    { name: "helpfulcleaners.com"},
    { name: "lawnmode.com"},
    { name: "noticeofwork.com"},
    { name: "testapi.co"},
    { name: "remembrance.app"},
    { name: "uptimeee.com"},
]
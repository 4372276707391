import React, {Component, Fragment, useState} from "react";
import Column from "../components/Column";
import Modal from "../components/Modal";
import {domains, Domain} from "../constants"
import Icon from "../assets/img/sweetdomains-icon.svg"
import TWLogo from "../assets/img/tw-logo.svg"
import Card from "../components/Card";
import Banner from "../components/Banner";


const HomeView = () => {
    const [displayModal, setDisplayModal] = useState(false)
    const [domain, setDomain] = useState("");
    const renderDomains = () => {
        return domains.map((domain: Domain) => {
            return (<Column className={'d-flex align-items-stretch'} md={4}>
                <Card
                    callback={() => toggleModal(domain.name)}
                    name={domain.name}
                    description={domain.description}
                    price={domain.price}
                />
            </Column>)
        })
    }

    const toggleModal = (domain: string) => {
        setDomain(domain)
        setDisplayModal(!displayModal)
    }

    return (
        <Fragment>
        <div className="bg-white">
            <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
                <div className={'my-4'}>
                    <img className={'donut d-block m-auto'} src={Icon} alt={"SweetDomains.io Icon"}/>
                    <h2 className={'text-4xl font-weight-bold text-center mt-4'}>Choose from <span className={'highlight'}>{domains.length}</span> Sweet Domains.</h2>
                    <p className={'text-center'}>Click <b><code className={'pink'}>verify ownership</code></b> to see a
                        TXT record
                        displaying <b><code className={'pink'}>domain-owner=sweetdomains.io</code></b></p>
                    <Banner text={"Any one of these sweet domains could be yours in as soon as 24 hours. What are you waiting for?!"} mobileText={"Get your domain in as soon as 24 hrs!"}/>
                </div>
                <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-3 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
                    { renderDomains() }
                </div>
            </div>
            <div className="my-5 text-center">
                <a className={'d-inline'} target={'_blank'} href={'https://timwheeler.com?ref=sweetdomains.io'}>
                    <img
                        alt={'TimWheeler.com Logo'}
                        src={TWLogo}
                        style={{height: 35, width: 'auto'}}
                        className={'block m-auto'}
                    />
                    <code className={'pink text-xs d-inline text-monospace font-bold'}>Built by Tim
                        Wheeler</code>
                </a>
            </div>
        </div>
            { displayModal ? (<Modal domain={domain} callback={toggleModal}>
                <form action="https://sendpoint.io/id/sweetdomains" method="POST">
                    <input type="text" name="first_name" required placeholder={'First Name*'}/>
                    <input type="text" name="last_name" required placeholder={'Last Name*'}/>
                    <input type="email" name="email" required placeholder={'Email*'}/>
                    <input type="tel" name="phone" placeholder={'Phone'}/>
                    <textarea name="domains" required placeholder="Domain(s) you're interested in*"
                              value={domain}/>
                    <input type="text" name="offer" required placeholder={"Offer*"}/>
                    <div className={"g-recaptcha"}
                         data-sitekey="6LdKc9QUAAAAAKYvjsIotCWOWJbif74hHkZT25lB"/>
                    <input className={'form-button'} type="submit" value="Reach Out, Yo."/>
                </form>
            </Modal>) : null }

        </Fragment>
    )
}

export default HomeView;